import React from "react"
import appStore from "../images/app-store_135x40.svg"
import { OutboundLink } from "gatsby-plugin-google-analytics"

interface AppStoreProps {
  url?: string
}

const AppStore: React.FunctionComponent<AppStoreProps> = props => {
  if (!props.url) {
    return null
  }
  return (
    <OutboundLink href={props.url}>
      <img
        src={appStore}
        width={135}
        height={40}
        alt="Download on the App Store"
      />
    </OutboundLink>
  )
}

export default AppStore
