import React from "react"
import playStore from "../images/google-play_135x40.svg"
import { OutboundLink } from "gatsby-plugin-google-analytics"

interface PlayStoreProps {
  url?: string
}

const PlayStore: React.FunctionComponent<PlayStoreProps> = props => {
  if (!props.url) {
    return null
  }
  return (
    <OutboundLink href={props.url}>
      <img
        src={playStore}
        width={135}
        height={40}
        alt="Get it on Google Play"
      />
    </OutboundLink>
  )
}

export default PlayStore
